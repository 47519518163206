import React from "react";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { bool, func } from "prop-types";
import { StyledBurger } from "../../../../../styles/Header.styled";

const Burger = ({ open, setOpen }) => {
  const useStyles = makeStyles((theme) => ({
    path: {
      fill: open ? "#000" : "#fff",
      fontSize: "62.8px !important",
      [theme.breakpoints.only("xs")]: {
        fontSize: "42.8px !important",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "62.8px !important",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "62.8px !important",
      },
    },
  }));
  const classes = useStyles();
  return (
    <StyledBurger
      open={open}
      onClick={() => setOpen(!open)}
      aria-label={open ? "Stäng meny" : "Öppna meny"}
      aria-haspopup={!open}
      aria-expanded={open}
    >
      {open ? (
        <CloseIcon fontSize="large" className={classes.path} />
      ) : (
        <MenuIcon className={classes.path} />
      )}
    </StyledBurger>
  );
};

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;
