import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import HamburgerMenu from "./Menu/Menu";
import { makeStyles } from "@material-ui/core";
import Logo from "./Logo";
import HomeButton from "./HomeButton";
import PageIdentifier from "./PageIdentifier";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    backgroundColor: "#363636",
    borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  },
  navHeader: {
    padding: "26px 20px",
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 auto",
    [theme.breakpoints.only("xs")]: {
      padding: "20px 14px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "26px 20px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "26px 20px",
    },
  },
  navLeft: {
    display: "flex",
    flexDirection: "column",
    width: "62.8px",
    [theme.breakpoints.only("xs")]: {
      marginRight: "0.5rem",
      width: "42.8px",
    },
    [theme.breakpoints.only("sm")]: {
      marginRight: "0.5rem",
      width: "62.8px",
    },
    [theme.breakpoints.only("md")]: {
      marginRight: "0.5rem",
      width: "62.8px",
    },
  },
  navCenter: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    textAlign: "center",
  },
  navRight: {
    width: "10%",
  },
}));

const Header = () => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <header className={classes.header}>
        <div className={classes.navHeader}>
          <div className={classes.navLeft}>
            {location.pathname === "/" ? null : (
              <HomeButton setOpen={setOpen} open={open} />
            )}
            <PageIdentifier />
          </div>
          <div className={classes.navCenter}>
            <Logo />
          </div>
          <div className={classes.navRight}>
            <HamburgerMenu open={open} setOpen={setOpen} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
