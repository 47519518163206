import { ReactComponent as PictogramJag } from "../assets/images/homepage/jag.svg";
import { ReactComponent as PictogramLjuga } from "../assets/images/homepage/ljuga.svg";
import { ReactComponent as PictogramVälja } from "../assets/images/homepage/välja.svg";
import { ReactComponent as PictogramVetInte } from "../assets/images/homepage/vet_inte.svg";
import { ReactComponent as PictogramBildmeddelande } from "../assets/images/homepage/bildmeddelande.svg";
import { ReactComponent as PictogramInfo } from "../assets/images/homepage/information.svg";

export const functionsArray = [
  {
    id: 1,
    name: "Bluffnyheter",
    alt: "Pictogram ljuga",
    svg: <PictogramLjuga />,
    color: "#F19494",
    href: "/bluffnyheter",
    class: "card_fakeNews",
  },
  {
    id: 2,
    name: "Säker på nätet",
    alt: "Pictogram välja",
    svg: <PictogramVälja />,
    color: "#7EFC95",
    href: "/saker-pa-natet",
    class: "card_choose",
  },
  {
    id: 3,
    name: "Du bestämmer",
    alt: "Pictogram jag",
    svg: <PictogramJag />,
    color: "#FEFF67",
    href: "/du-bestammer",
    class: "card_decide",
  },
  {
    id: 4,
    name: "Riskbedömning",
    alt: "Pictogram vet inte",
    svg: <PictogramVetInte />,
    color: "#BECBF9",
    href: "/riskbedomning",
    class: "card_analsysis",
  },
  {
    id: 5,
    name: "Mer tips på nätet",
    alt: "Pictogram bildmeddelande",
    svg: <PictogramBildmeddelande />,
    color: "#E0B64D",
    href: "/mer-tips",
    class: "card_tips",
  },
  {
    id: 6,
    name: "Pedagoger & personal",
    alt: "Pictogram information",
    svg: <PictogramInfo />,
    color: "#F688DB",
    href: "/personal",
    class: "card_personal",
  },
];
