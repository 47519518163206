import React from "react";
import { Link } from "react-router-dom";
import { object, func } from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

const MenuItem = ({ item, setOpen }) => {
  const { href, name, svg, color } = item;

  const useStyles = makeStyles({
    link: {
      fontSize: "2rem",
      padding: "0.75rem 0",
      paddingRight: "10px",
      marginBottom: "1rem",
      fontWeight: "bold",
      letterSpacing: "0.25rem",
      color: "#000",
      textDecoration: "none",
      transition: "color 0.3s linear",
      borderRadius: "10px",
      boxShadow: "1px 1px 2px rgba(0,0,0,0.5)",
      background: color,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  });
  const classes = useStyles();
  return (
    <>
      <Link
        to={href}
        role="menuitem"
        className={classes.link}
        onClick={() => setOpen(false)}
      >
        {svg}
        <Typography component="h1">{name}</Typography>
      </Link>
    </>
  );
};

MenuItem.propTypes = {
  item: object.isRequired,
  setOpen: func.isRequired,
};

export default MenuItem;
