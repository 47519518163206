import { useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { func } from "prop-types";
import { makeStyles } from "@material-ui/core";

function HomeButton({ open, setOpen }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: open ? "fixed" : "relative",
      display: "flex",
      background: "transparent",
      border: "none",
      cursor: "pointer",
      padding: "0",
      margin: "0",
      zIndex: "2",
      flexGrow: "1",
      [theme.breakpoints.only("xs")]: {
        position: "relative",
      },
    },
    path: {
      fill: open ? "#000" : "#fff",
      fontSize: "62.8px !important",
      [theme.breakpoints.only("xs")]: {
        fontSize: "42.8px !important",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "62.8px !important",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "62.8px !important",
      },
    },
  }));
  const classes = useStyles();
  let history = useHistory();

  function handleClick() {
    history.push("/");
    setOpen(false);
  }

  return (
    <button className={classes.root} aria-label="Tillbaka till startsidan">
      <HomeIcon
        fontSize="large"
        onClick={handleClick}
        className={classes.path}
      />
    </button>
  );
}

HomeButton.propTypes = {
  setOpen: func.isRequired,
};

export default HomeButton;
