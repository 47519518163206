import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { Grid } from "@material-ui/core";
import Loading from "./Components/Loading";
import Header from "./Components/shared/Header/Header";
import Footer from "./Components/shared/Footer/Footer";

const HomePage = lazy(() => import("./pages/Home.page"));
const FakeNewsPage = lazy(() => import("./pages/FakeNews.page"));
const ChoosePage = lazy(() => import("./pages/Choose.page"));
const MoreTipsPage = lazy(() => import("./pages/MoreTips.page"));
const PersonalPage = lazy(() => import("./pages/Personal.page"));
const RiskAnalisysPage = lazy(() => import("./pages/RiskAnalisys.page"));
const YouDecidePage = lazy(() => import("./pages/YouDecide.page"));

function App() {
  return (
    <Router>
      <div className="page-container">
        <Header />
        <main className="main-container">
          <Grid container>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/bluffnyheter" component={FakeNewsPage} />
                <Route path="/saker-pa-natet" component={ChoosePage} />
                <Route path="/du-bestammer" component={YouDecidePage} />
                <Route path="/riskbedomning" component={RiskAnalisysPage} />
                <Route path="/mer-tips" component={MoreTipsPage} />
                <Route path="/personal" component={PersonalPage} />
              </Switch>
            </Suspense>
          </Grid>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
