import React from "react";
import { makeStyles } from "@material-ui/core";
import skandia from "../../../assets/images/footer/skandia.png";
import mora from "../../../assets/images/footer/mora.jpg";
import digijag from "../../../assets/images/footer/DigiJag-vit.svg";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "relative",
    bottom: "0",
    width: "100%",
    height: "200px",
    background: "#363636",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      height: "120px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "150px",
    },
    [theme.breakpoints.only("md")]: {
      height: "200px",
    },
  },
  div: {
    width: "30%",
    display: "flex",
    justifyContent: "center",
  },
  imgSkandia: {
    width: "30%",
    [theme.breakpoints.only("xs")]: {
      width: "80%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
  },
  imgMora: {
    width: "250px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
  },
  imgDigijag: {
    marginLeft: "10%",
    width: "200px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.only("md")]: {
      width: "80%",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.div}>
        <a
          href="https://www.ideerforlivet.se/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={skandia} alt="" className={classes.imgSkandia} />
        </a>
      </div>
      <div className={classes.div}>
        <a
          href="https://www.morafolkhogskola.se/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={mora} alt="" className={classes.imgMora} />
        </a>
      </div>
      <div className={classes.div}>
        <a href="https://www.digijagtest.se" target="_blank" rel="noreferrer">
          <img src={digijag} alt="" className={classes.imgDigijag} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
