import React from "react";
import { useLocation } from "react-router-dom";
import { functionsArray } from "../../../hooks/AppFunctions";
import { StyledIdentifier } from "../../../styles/Header.styled";
import { makeStyles } from "@material-ui/core";

function PageIdentifier() {
  const location = useLocation();

  let svg;
  let color;

  functionsArray.map((item) => {
    if (item.href === location.pathname) {
      svg = item.svg;
      color = item.color;
    }
    return [svg, color];
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: color,
    },
  }));
  const classes = useStyles();
  return <StyledIdentifier className={classes.root}>{svg}</StyledIdentifier>;
}

export default PageIdentifier;
