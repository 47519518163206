import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as PictogramDator } from "../../../assets/images/homepage/dator_vit.svg";
import { ReactComponent as PictogramTrygg } from "../../../assets/images/homepage/trygg_vit.svg";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Montserrat",
    fontSize: "2.5rem",
    fontWeight: "bold",
    lineHeight: "3.0625rem",
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.25rem",
      lineHeight: "1.625rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "2rem",
      lineHeight: "2.625rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "2rem",
      lineHeight: "2.625rem",
    },
  },
  img: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginTop: "auto",
  },
  svg: {
    marginRight: "1rem",
    width: "100px",
    [theme.breakpoints.only("xs")]: {
      width: "60px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "90px",
    },
  },
}));

const Logo = () => {
  const classes = useStyles();
  return (
    <>
      <Typography component="h1" color="primary" className={classes.text}>
        Nätet som en trygg och säker plats
      </Typography>
      <div className={classes.img}>
        <PictogramDator className={classes.svg} />
        <PictogramTrygg className={classes.svg} />
      </div>
    </>
  );
};

export default Logo;
