import { createMuiTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { red } from "@material-ui/core/colors";

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  palette: {
    type: "light",
    primary: {
      main: "#7CDDFD",
      light: "#61dafb",
      dark: "#21a1c4",
    },
    secondary: {
      main: "#b5ecfb",
      youDecide: "#FEFF67",
      dark: "#21a1c4",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        padding: "0",
        margin: "0",
      },
    },
    MuiPaper: {
      root: {
        padding: "1.25rem 0.625rem",
        margin: "0.625rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        lineHeight: "1.8125rem",

        [breakpoints.only("xs")]: {
          fontSize: "1rem",
          lineHeight: "1.25rem",
        },
        [breakpoints.only("sm")]: {
          fontSize: "1.25rem",
          lineHeight: "1.5rem",
        },
      },
      body2: {
        fontSize: {
          [breakpoints.only("xs")]: {
            fontSize: "1rem",
            lineHeight: "1.25rem",
          },
          [breakpoints.only("sm")]: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
          },
        },
      },
      button: {
        fontWeight: "bold",
      },
    },
    MuiGrid: {
      container: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
        marginBottom: "2rem",
        [breakpoints.only("xs")]: {
          marginTop: "1rem",
          marginBottom: "1rem",
        },
        [breakpoints.only("sm")]: {
          marginTop: "1.3rem",
          marginBottom: "1.3rem",
        },
      },
    },
    MuiListItem: {
      root: {
        display: "flex",
        flexDirection: "row",
        border: "1px solid #000",
        borderRadius: "10px",
        alignItems: "center",
        marginBottom: "10px",
      },
    },
    MuiButton: {
      root: {
        width: "150px",
      },
    },
    MuiAlert: {
      icon: {
        alignSelf: "baseline",
      },
    },
  },
});
export default theme;
