import styled from "styled-components";

export const StyledMenu = styled.nav`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  text-align: left;

  z-index: 1;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};

  .menu-container {
    position: absolute;
    margin-top: 100px;
  }

  @media (max-width: 576px) {
    width: 100%;
    .menu-container {
      margin-top: 80px;
    }
  }

  a {
    @media (max-width: 576px) {
      text-align: left;
    }

    &:hover,
    :focus {
      color: #343078;
      border-color: #343078;
    }
    svg {
      width: 100px !important;
      height: 100px !important;
      margin-right: 20px;
      margin-left: 10px;
      @media (max-width: 576px) {
        width: 60px !important;
        height: 60px !important;
      }
    }
  }
`;

export const StyledBurger = styled.button`
  position: ${({ open }) => (open ? "fixed" : "relative")};
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: 2;
  @media (max-width: 600px) {
    position: relative;
  } ;
`;

export const StyledIdentifier = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: auto;
    svg {
      width: 70px !important;
      height: 70px !important;
      @meda (max-width: 600px) {
        width: 42.8px !important;
        height: 42.8px !important;
      }
      @meda (max-width: 960px) {
        width: 70px !important;
        height: 70px !important;
      }
    }
  }
`;
