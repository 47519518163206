import React from "react";
import { bool, func } from "prop-types";
import Burger from "./Button/Burger";
import MenuItem from "./MenuItem";
import { functionsArray } from "../../../../hooks/AppFunctions";
import { StyledMenu } from "../../../../styles/Header.styled";

const HamburgerMenu = ({ open, setOpen }) => {
  return (
    <>
      <Burger open={open} setOpen={setOpen} />
      {open ? (
        <StyledMenu open={open} role="menu">
          <div className="menu-container">
            {functionsArray.map((item) => (
              <MenuItem item={item} setOpen={setOpen} key={item.name} />
            ))}
          </div>
        </StyledMenu>
      ) : null}
    </>
  );
};

HamburgerMenu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default HamburgerMenu;
